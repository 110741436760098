import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import { AuthFetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const bannerDefault = {
  IdBanner: 0,
  NombreBanner: "",
  UrlBanner: ""
}

const urlBanners = process.env.REACT_APP_PROMETHEUS_API + '/banners' 

export const useBanners = () => {
  const { stateUser } = useContext(UserContext)
  const [ banners, setBanners] = useState([]);
  const [ banner, setBanner ] = useState(bannerDefault);
  const [ isOpenModal, openModal, closeModal ] = useModal();
  const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

  useEffect(() => {
      listarBanners();
  }, []);

  const listarBanners = async () => {
    const response = await AuthFetch({url: urlBanners + '?' + new URLSearchParams({base_datos:stateUser?.baseDatos})});
    if (response.isValid) {
      setBanners(response.content);
    } else {
      notify(response.error, 'error');
    }
  }

  const eliminarBanner = async (idBanner) => {
    const response = await AuthFetch({url: `${urlBanners}/${idBanner}?`+ new URLSearchParams({base_datos : stateUser?.baseDatos}),
      method: 'DELETE'
    })
    if (response.isValid) {
      listarBanners()
      notify(response.content, 'success')
    } else { 
      notify(response.error, 'error')
    }
    closeModalEliminar()
  }

  const guardarBanner = async () => {
    const response = await AuthFetch({
      url: `${urlBanners}?` + new URLSearchParams({
        base_datos: stateUser?.baseDatos
      }),
      method:'POST',
      body: JSON.stringify({
        NombreBanner: banner.NombreBanner || "Banner Promocional",
        UrlBanner: banner.UrlBanner,
      })
    });

    if (response.isValid) {
      notify(response.content, 'success');
      setBanner(bannerDefault);
      await listarBanners();
      closeModal()
    } else { 
      notify(response.error,'error');
    }
  }
    
  const editarValorBanner = (key, value) => {
    setBanner(banner => {
      return {
        ...banner,
        [key]: value
      }
    });
  }

  return {
    banner,
    listarBanners,
    banners,
    editarValorBanner,
    guardarBanner,
    isOpenModal,
    closeModal,
    eliminarBanner,
    isOpenModalEliminar,
    closeModalEliminar,
  }
}