import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  Switch,
  Grid,
  InputAdornment,
} from "@mui/material";
import { Delete, UploadFile } from "@mui/icons-material";

export const MetodosPago = () => {
  const [metodosPago, setMetodosPago] = useState([]);
  const [nuevoMetodo, setNuevoMetodo] = useState({
    nombre: "",
    detalles: "",
    habilitado: true,
    icono: "",
    banco: "",
  });

  const bancos = ["BCP", "BBVA", "Interbank", "Scotiabank", "Banco de la Nación"];

  const handleIconoUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setNuevoMetodo({ ...nuevoMetodo, icono: e.target.result });
      };
      reader.readAsDataURL(file);
    } else {
      alert("Por favor, suba una imagen válida.");
    }
  };

  const agregarMetodoPago = () => {
    setMetodosPago([...metodosPago, { ...nuevoMetodo, id: Date.now() }]);
    setNuevoMetodo({ nombre: "", detalles: "", habilitado: true, icono: "", banco: "" });
  };

  const eliminarMetodoPago = (id) => {
    setMetodosPago(metodosPago.filter((metodo) => metodo.id !== id));
  };

  const habilitarDeshabilitarMetodoPago = (id) => {
    setMetodosPago(
      metodosPago.map((metodo) =>
        metodo.id === id ? { ...metodo, habilitado: !metodo.habilitado } : metodo
      )
    );
  };

  return (
    <div className="p-4">
      <Typography variant="h5" gutterBottom>
        Métodos de Pago
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nombre del método"
            value={nuevoMetodo.nombre}
            onChange={(e) => setNuevoMetodo({ ...nuevoMetodo, nombre: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Detalles"
            multiline
            rows={2}
            value={nuevoMetodo.detalles}
            onChange={(e) => setNuevoMetodo({ ...nuevoMetodo, detalles: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            accept="image/*"
            id="icono-upload"
            type="file"
            onChange={handleIconoUpload}
            style={{ display: "none" }}
          />
          <label htmlFor="icono-upload">
            <Button
              variant="contained"
              component="span"
              fullWidth
              startIcon={<UploadFile />}
            >
              Subir Ícono
            </Button>
          </label>
        </Grid>

        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            value={nuevoMetodo.banco}
            onChange={(e) => setNuevoMetodo({ ...nuevoMetodo, banco: e.target.value })}
            displayEmpty
          >
            <MenuItem value="">Seleccione un banco</MenuItem>
            {bancos.map((banco, index) => (
              <MenuItem key={index} value={banco}>
                {banco}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={agregarMetodoPago}
          >
            Agregar Método de Pago
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Listado de Métodos de Pago
      </Typography>

      <Grid container spacing={2}>
        {metodosPago.map((metodo) => (
          <Grid item xs={12} sm={6} md={4} key={metodo.id}>
            <Card sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {metodo.icono && (
                <img src={metodo.icono} alt="Ícono" style={{ width: 50, height: 50, marginTop: 10 }} />
              )}
              <CardContent>
                <Typography variant="h6">{metodo.nombre}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {metodo.detalles}
                </Typography>
                {metodo.banco && (
                  <Typography variant="body2" color="textSecondary">
                    Banco: {metodo.banco}
                  </Typography>
                )}
              </CardContent>
              <Grid container spacing={1} sx={{ pb: 2 }}>
                <Grid item>
                  <Switch
                    checked={metodo.habilitado}
                    onChange={() => habilitarDeshabilitarMetodoPago(metodo.id)}
                  />
                </Grid>
                <Grid item>
                  <IconButton color="error" onClick={() => eliminarMetodoPago(metodo.id)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
