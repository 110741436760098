import { useState, useEffect, useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const productoDefault = {
    IdProducto: 0,
    Nombre: "",
    Descripcion: "",
    Stock: 0,
    StockMinimo: 0,
    StockMaximo: 0,
    PrecioVenta: 0,
    PrecioCompra: 0,
    PrecioPromocional: 0,
    IdEstado: 1,
    IdCategoria: 0,
    Destacado: 0,
    IdMarca: 0,
    UsuarioRegistro: "",
    Publicado: 0,
    Fotos: "",
}

export const useProductos = () => {
  const { stateUser } = useContext(UserContext)
  const [ productos, setProductos] = useState([]);
  const [ producto, setProducto ] = useState(productoDefault);
  const [ isOpenModal, openModal, closeModal ] = useModal();
  const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();
  const [fotoFiles, setFotoFiles] = useState([])
  const [urlsFotos, setUrlsFotos] = useState('')
  const urlProductos = process.env.REACT_APP_PROMETHEUS_API + '/productos'
  const promesasPendientes = {};


  useEffect(() => {
      listarProductos();
  }, []);

  const listarProductos = async () => {
    const response = await Fetch({
      url: urlProductos + '?'+ new URLSearchParams({
        base_datos    : stateUser?.baseDatos
      })
    });
    if (response.isValid) {
      setProductos(response.content);
    } else {
      notify(response.error, 'error');
    }
  }

  const obtenerProducto = async (idProducto) => {
    if (idProducto) {
      const response = await AuthFetch({ url: `${urlProductos}/${idProducto}?` + new URLSearchParams({base_datos: stateUser?.baseDatos}) });
      if (response.isValid) {
        setProducto(response.content);
      } else {
        notify(response.error, 'error');
      }
    } else {
      setProducto(productoDefault);
    }
    openModal();
  };

  const productoEliminar = async (idProducto) =>{
    if (idProducto) {
      const response = await AuthFetch({ url: `${urlProductos}/${idProducto}?` + new URLSearchParams({base_datos: stateUser?.baseDatos}) });
      if (response.isValid) {
        setProducto(response.content);
      } else {
        notify(response.error, 'error');
      }
    } else {
      setProducto(productoDefault);
    }
    openModalEliminar();
  }

  const eliminarProducto = async (idProducto) => {
    const response = await AuthFetch({ url: `${urlProductos}/${idProducto}?` + new URLSearchParams({base_datos: stateUser?.baseDatos}),
      method: 'DELETE',
    });
    if (response.isValid) {
      notify(response.content, 'success');
      listarProductos();
    } else { 
      notify(response.error, 'error');
    }
    closeModalEliminar();
  };
  
  const guardarProductoConFoto = async (archivos) => {
    if (!archivos || archivos.length === 0) { 
      guardarEditarProducto()
      return
    }

    const urlResponse = archivos.map(async (data) => {
      return await enviarMinio(data);
    })
    const urlArchivoJson = await Promise.all(urlResponse);
    const fotosActuales = producto.Fotos ? producto.Fotos.split('|') : [];
    urlArchivoJson.forEach((url, index) => {
      fotosActuales[index] = url;
    });
    const nuevasFotos = fotosActuales.join('|');
    setUrlsFotos(urlArchivoJson.join('|'))
    producto.Fotos = nuevasFotos
    guardarEditarProducto()
    return urlArchivoJson.join('|');
  }

  const procesarImagen = async (file, maxWidth = 1024, maxHeight = 1024, quality = 0.8) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let width = img.width;
          let height = img.height;
          if (width > maxWidth || height > maxHeight) {
            if (width > height) {
              height = Math.round((maxHeight / width) * height);
              width = maxWidth;
            } else {
              width = Math.round((maxWidth / height) * width);
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const nuevoArchivo = new File([blob], file.name, {
                  type: blob.type,
                });
                resolve(nuevoArchivo);
              } else {
                reject(new Error('Error al procesar la imagen.'));
              }
            },
            'image/jpeg',
            quality
          );
        };
        img.onerror = () => reject(new Error('Error al cargar la imagen.'));
        img.src = e.target.result;
      };
      reader.onerror = () => reject(new Error('Error al leer el archivo.'));
      reader.readAsDataURL(file);
    });
  };
  

 const enviarMinio = async (file) => {
   const imagenProcesada = await procesarImagen(file);
   if (promesasPendientes[file.identificador]) {
     await promesasPendientes[file.identificador];
   }
   const formData = new FormData();
   formData.append('archivo', imagenProcesada);
   const timestamp = Date.now();
   const nombreProducto = `${producto.Nombre.replace(/\s+/g, '_')}_${timestamp}`;
   console.log("stateUser.baseDatos------------>", stateUser.baseDatos)
   const queryParameters = {
     bucket: 'prometheus',
     carpeta: `${stateUser.baseDatos}/productos`,
     nombre: nombreProducto,
   };

   const urlParams = new URLSearchParams(queryParameters).toString();
   const urlEnvioMinio = `https://miniowebapi.prometheusperu.com/subirFoto/?${urlParams}`;
   promesasPendientes[file.identificador] = fetch(urlEnvioMinio, {
     method: 'POST',
     body: formData,
   })
     .then((res) => {
       if (!res.ok) {
         throw new Error(`Error en la subida de archivo. Código de estado: ${res.status}`);
        }
       return res.json();
    })
     .then((response) => {
       if (response.isValid) {

         notify("Imagen guardada correctamente", "success");
          return `https://miniowebapi.prometheusperu.com/verImagen/${queryParameters.bucket}/${queryParameters.carpeta}/${nombreProducto}`
        } else {
         notify(response.content, "error");
         throw new Error(response.content);
        }
    })
    .catch((e) => {
      notify(`Error al guardar la imagen: ${e.message}`, 'error');
      throw e;
    });

   const urlReturn = await promesasPendientes[file.identificador];
   delete promesasPendientes[file.identificador];
   return urlReturn;
 };
  
  const guardarEditarProducto = async () => {
    if (!producto.Nombre) {
      notify("Debe ingresar un nombre de producto", 'error');
      return;
    }
    if (!producto.IdCategoria) {
      notify("Debe seleccionar una categoría", 'error');
      return;
    }
    if (!producto.IdMarca) {
      notify("Debe seleccionar una marca", 'error');
      return;
    }
    
    const esGuardar = producto.IdProducto <= 0;
    const response = await AuthFetch({
       url: `${urlProductos}/${esGuardar? '' : ''+ producto.IdProducto}?`+ new URLSearchParams({
        base_datos: stateUser?.baseDatos
      }),
      method: esGuardar ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...producto,
        UsuarioRegistro: stateUser?.usuario,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.isValid) {
      setProducto(productoDefault);
      await listarProductos();
      closeModal();
      notify(response.content, 'success');
    } else {
      notify(response.error, 'error');
    }
  };

  const editarValorProducto = (key, value) => {
    setProducto(producto => {
      return {
        ...producto,
        [key]: value
      }
    });
  }

  return {
    producto, 
    listarProductos, 
    guardarProductoConFoto,
    productos,
    editarValorProducto,
    obtenerProducto,
    isOpenModal,
    closeModal,
    fotoFiles,
    setFotoFiles,
    eliminarProducto,
    isOpenModalEliminar, 
    closeModalEliminar,
    productoEliminar 
  }

}