import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, UploadFile } from "@mui/icons-material";
import { UserContext } from "../../context/provider/UserProvider";
import { useBanners } from "../../hooks/useBanners";

const enviarMinio = async (file,datosUsuario) => {
  const formData = new FormData();
  formData.append("archivo", file);
  const timestamp = Date.now();
  const nombreArchivo = `banner_${timestamp}`;

  const queryParameters = {
    bucket: "prometheus",
    carpeta: `${datosUsuario.baseDatos}/banners`,
    nombre: nombreArchivo,
  };

  const urlParams = new URLSearchParams(queryParameters).toString();
  const urlEnvioMinio = `https://miniowebapi.prometheusperu.com/subirFoto/?${urlParams}`;

  try {
    const res = await fetch(urlEnvioMinio, {
      method: "POST",
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`Error en la subida de archivo. Código de estado: ${res.status}`);
    }

    const response = await res.json();
    if (response.isValid) {
      return `https://miniowebapi.prometheusperu.com/verImagen/${queryParameters.bucket}/${queryParameters.carpeta}/${nombreArchivo}`;
    } else {
      throw new Error(response.content);
    }
  } catch (e) {
    console.error("Error al subir la imagen:", e.message);
    return null;
  }
};

export const Banners = () => {

  const {
    banner,
    listarBanners,
    banners,
    editarValorBanner,
    guardarBanner,
    isOpenModal,
    closeModal,
    eliminarBanner,
    isOpenModalEliminar,
    closeModalEliminar,
  } = useBanners()


  const { stateUser } = useContext(UserContext)
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && ["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
      setLoading(true);
      try {
        const urlMinio = await enviarMinio(file, stateUser);
        if (urlMinio) {
          editarValorBanner("UrlBanner", urlMinio)
          setPreviewImage({ src: urlMinio, file });
        }
      } catch (error) {
        console.error("Error al subir imagen:", error);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Por favor, suba una imagen en formato JPEG, PNG o WebP.");
    }
  };

  return (
    <div className="p-2">
      <Typography variant="h5" gutterBottom>
        Banners
      </Typography>

      <Grid container spacing={1}>
        {/* Área de Preview */}
        <Grid item xs={12}>
          <Card sx={{ width: "100%", height: 300, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#eee" }}>
            {previewImage ? (
              <>
                <CardMedia component="img" image={previewImage.src} alt="Preview" sx={{ maxHeight: "100%", objectFit: "cover" }} />
                <Tooltip title="Eliminar">
                  <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={() => setPreviewImage(null)}>
                    <Delete color="error" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Typography color="gray">Tamaño recomendado (1500x300 px). Mantenga el contenido principal al centro.</Typography>
            )}
          </Card>
          {previewImage && (
            <Button variant="contained" color="success" fullWidth sx={{ mt: 2 }} onClick={guardarBanner}>
              Guardar Imagen
            </Button>
          )}
        </Grid>

        {/* Botón para subir imagen */}
        <Grid item xs={12}>
          <input type="file" accept="image/jpeg,image/png,image/webp" style={{ display: "none" }} id="image-upload" onChange={handleImageUpload} />
          <label htmlFor="image-upload">
            <Button variant="contained" component="span" startIcon={<UploadFile />} fullWidth>
              Subir Imagen
            </Button>
          </label>
          {loading && (
            <div className="flex justify-center mt-2">
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>Cargando...</Typography>
            </div>
          )}
        </Grid>

        {/* Listado de imágenes */}
        {banners.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ textAlign: "center", mb: 2 }}>
              Banners Subidos
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {banners.map((image) => (
                <Grid item key={image.id} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      position: "relative",
                      borderRadius: 2,
                      boxShadow: 3,
                      overflow: "hidden",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": { transform: "scale(1.02)" }
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={image.UrlBanner}
                      alt={`Banner-${image.IdBanner}`}
                      sx={{
                        height: 120,
                        objectFit: "cover",
                        borderRadius: "8px 8px 0 0"
                      }}
                    />
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        minHeight: 50
                      }}
                    >
                      <Tooltip title="Eliminar">
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.8)" }
                          }}
                          onClick={() => eliminarBanner(image.IdBanner)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "#333"
                        }}
                      >
                        {image.NombreBanner} {image.IdBanner}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

      </Grid>
    </div>
  );
};
